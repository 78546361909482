import { SrsApi } from '@/srs/services/SrsApi';
import { computed, Ref, ref, watch } from 'vue';
import isEqual from 'lodash.isequal';
import { useStore } from 'vuex';
import CancelablePromise from '@nextgis/cancelable-promise';
import { debounce } from '@nextgis/utils';
import useAbortController from './useAbortController';
import type { Srs } from '../srs/interfaces/Srs';
import type { SrsItemsQueryParams } from '../srs/interfaces/SrsItemsQueryParams';
import type { PaginationRequestOptions } from '../options/PaginationRequestOptions';

const useSrsList = async (
  params: Ref<SrsItemsQueryParams>,
  paginationOptions: Ref<PaginationRequestOptions>,
  withInfiniteLoading: Ref<boolean> = ref(false)
) => {
  const store = useStore();
  const { abortController, isLoading: isListLoading } = useAbortController();
  const { abortController: abortControllerForTotalCount, isLoading: isTotalCountLoading } =
    useAbortController();
  const srsItems: Ref<Srs[]> = ref([]);
  const srsTotalCount: Ref<number> = ref(0);

  let loadingTimer: ReturnType<typeof setTimeout> | null = null;

  const isLoading = ref(false);
  watch([isListLoading, isTotalCountLoading], ([listVal, totalVal]) => {
    if (loadingTimer) clearTimeout(loadingTimer);
    // set timeout to avoid flickring loading spinner
    if (!totalVal) {
      loadingTimer = setTimeout(() => {
        isLoading.value = false;
      }, 300);
    } else {
      isLoading.value = listVal || totalVal;
    }
  });

  const checkIsBookmarked = (srsId: number) => {
    const result = store.state.srs.bookmarks.filter((item: Partial<Srs>) => item.id === srsId);
    return !!result.length;
  };

  const getSrsItemsWithBookmarks = (items: Srs[]) =>
    items.map((item) => ({
      ...item,
      is_bookmarked: checkIsBookmarked(item.id),
    }));

  const fetchSrsItems = async (
    options: SrsItemsQueryParams,
    pageOpts: PaginationRequestOptions
  ) => {
    try {
      const items = await abortController.add(SrsApi.find(options, pageOpts));
      const itemsWithBookmarks = getSrsItemsWithBookmarks(items);
      srsItems.value = withInfiniteLoading.value
        ? [...srsItems.value, ...itemsWithBookmarks]
        : itemsWithBookmarks;
    } catch (er) {
      if (!(er instanceof CancelablePromise.CancelError)) {
        throw er;
      }
    }
  };

  const fetchSrsTotalCount = async () => {
    try {
      const srsItemsTotal = await abortControllerForTotalCount.add(
        SrsApi.find(params.value, { limit: 100 })
      );
      srsTotalCount.value = srsItemsTotal.length;
    } catch (er) {
      if (!(er instanceof CancelablePromise.CancelError)) {
        throw er;
      }
    }
  };

  const previousParams: Ref<null | SrsItemsQueryParams> = ref(null);

  watch(
    [() => ({ ...params.value }), () => ({ ...paginationOptions.value })],
    debounce(async () => {
      abortController.abort();
      abortControllerForTotalCount.abort();
      if (!isEqual(params.value, previousParams.value)) {
        previousParams.value = params.value;
        await fetchSrsTotalCount();
      }
      fetchSrsItems(params.value, paginationOptions.value);
    }, 300)
  );

  watch(store.state.srs.bookmarks, (val) => {
    srsItems.value = getSrsItemsWithBookmarks(srsItems.value);
  });

  return { srsItems, srsTotalCount, isSrsItemsLoading: isLoading };
};

export default useSrsList;
