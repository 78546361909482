import { ref, Ref } from "vue";

const useInfinitLoading = (initialValue:  boolean) => {
  const infiniteLoading: Ref<boolean> = ref(initialValue);
  
  function disableInfiniteLoading() {
    infiniteLoading.value = false;
  }

  function enableInfiniteLoading() {
    infiniteLoading.value = true;
  }
  return { infiniteLoading, disableInfiniteLoading, enableInfiniteLoading }
}

export default useInfinitLoading;