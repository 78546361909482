import CancelablePromise from '@nextgis/cancelable-promise';
import { ref } from 'vue';

const  useAbortController = () => {
  const isLoading = ref(false);

  const abortController = CancelablePromise.createControl({
    onStart: () => {
      isLoading.value = true;
    },
    onStop: () => {
      isLoading.value = false;
    },
  });
  
  return { abortController, isLoading }
}

export default useAbortController;